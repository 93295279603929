import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'tec-tc',
    loadChildren: () => import('./tec-tc/tec-tc.module').then( m => m.TecTcPageModule)
  },
  {
    path: 'conteudo-pratico',
    loadChildren: () => import('./conteudo-pratico/conteudo-pratico.module').then( m => m.ConteudoPraticoPageModule)
  },
  {
    path: 'creditos-app',
    loadChildren: () => import('./creditos-app/creditos-app.module').then( m => m.CreditosAppPageModule)
  },
  {
    path: 'sugestoes-app',
    loadChildren: () => import('./sugestoes-app/sugestoes-app.module').then( m => m.SugestoesAppPageModule)
  },
  {
    path: 'termos-de-uso',
    loadChildren: () => import('./termos-de-uso/termos-de-uso.module').then( m => m.TermosDeUsoPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'newapp',
    loadChildren: () => import('./newapp/newapp.module').then( m => m.NewappPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
